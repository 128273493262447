/** @format */

import FallbackIcon from '@atoms/FallbackIcon';
import { ResourceStatus } from '@common/application/enums/ResourceStatus';
import { ResourceUrl } from '@common/application/helpers/generateSignedUrlsForResults';
import { css } from '@emotion/react';
import React from 'react';

interface TableImageProps {
  maxHeight?: string;
  resource: ResourceUrl;
}

function TableImage(props: TableImageProps) {
  return (
    <span
      css={css`
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      {props.resource.url ? (
        <img
          css={css`
            max-height: ${props.maxHeight ?? '48px'};
            margin: 0 auto;
          `}
          src={props.resource.url as string}
          alt={'logo'}
        />
      ) : (
        <FallbackIcon
          isImage={true}
          css={css`
            max-height: ${props.maxHeight ?? '48px'};
            height: 38px;
            margin: 0 auto;
          `}
          resourceStatus={props.resource.urlStatus}
        />
      )}
    </span>
  );
}

export default TableImage;
